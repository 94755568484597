import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { phoneRegex } from '@/common/validations';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
export default {
  name: 'OneIncRPPView',
  data: function data() {
    return {
      loading: false,
      error: null,
      success: null,
      showPremodal: false,
      showOneIncModal: false,
      termsAndConditions: {
        status: 'notAccepted',
        show: false,
        displayError: false
      },
      preForm: {
        cardholderOnPolicyFlag: undefined,
        cardholderOnPolicyReason: '',
        cardholderPhone: '',
        cardholderEmail: ''
      },
      ETransID: null,
      accountGroupCode: null,
      customerID: null,
      sessionID: null,
      requestPayload: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('RPP', ['RPPInfoExists', 'prefillCardholderAddress', 'prefillCardholderAddress2', 'prefillCardholderPhone', 'prefillCardholderEmail', 'prefillCardholderCity', 'prefillCardholderState', 'prefillCardholderZip', 'eTransVendor', 'oneIncETransVendorParam', 'appID', 'RPPPullDate', 'RPPPrefillData'])), {}, {
    signedUp: function signedUp() {
      return this.$store.getters['billingAndPayments/RPPShowEdit'];
    },
    userID: function userID() {
      return this.$store.getters['siteConfig/RPPUserID'];
    },
    policyNumber: function policyNumber() {
      return this.$store.getters['account/selectedPolicyNumber'];
    },
    customerBillingAddressStreet: function customerBillingAddressStreet() {
      return this.prefillCardholderAddress.concat(', ', this.prefillCardholderAddress2, ', ', this.prefillCardholderCity, ', ', this.prefillCardholderState).replace(/ ,/g, '');
    }
  }),
  validations: {
    preForm: {
      cardholderOnPolicyFlag: {
        required: required
      },
      cardholderOnPolicyReason: {
        required: requiredIf(function (preForm) {
          return preForm.cardholderOnPolicyFlag === '0';
        })
      },
      cardholderPhone: {
        phoneRegex: phoneRegex
      },
      cardholderEmail: {
        email: email
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    // On page reload, we are kicking them back to the billing page.
    if (!this.RPPInfoExists) {
      this.$router.push({
        name: 'billingAndPayments'
      });
    } else {
      this.loading = true;
      this.includejQuery();
      setTimeout(function () {
        _this.includeOneIncJavascript();
      }, 1000);
      setTimeout(function () {
        _this.zeroDollarAuthSetupCall();
      }, 1500);
    }
  },
  methods: {
    submitPreform: function submitPreform() {
      this.error = null;
      this.$v.$reset();
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.error = 'Shared.Element.Errors.CorrectErrors';
        window.scrollTo(0, 0);
      } else {
        this.loading = true;
      }

      if (!this.signedUp) {
        if (this.termsAndConditions.status !== 'accepted') {
          this.termsAndConditions.displayError = true;
          this.termsAndConditions.show = false;
          this.error = 'Shared.Element.Errors.TermsAndConditions';
          this.loading = false;
          window.scrollTo(0, 0);
        } else {
          this.termsAndConditions.displayError = false;
        }
      }

      if (!this.error) {
        this.requestOneIncModal();
        this.showTestDiv = true;
        this.showPremodal = false;
      } else {
        this.loading = false;
      }
    },
    requestOneIncModal: function requestOneIncModal() {
      var authorizationStructure = {
        'paymentCategory': 'CreditCard',
        'billingZip': this.prefillCardholderZip,
        'billingAddressStreet': this.customerBillingAddressStreet,
        'policyHolderName': '',
        'clientReferenceData1': this.policyNumber,
        'confirmationDisplay': 'false',
        'customerId': this.customerID === '' ? null : this.customerID,
        'sessionId': this.sessionID,
        'displayMode': 'Inline',
        'allowClosing': 'true'
      };
      this.requestPayload = JSON.stringify(authorizationStructure);
      $('#portalOneContainer').data('portalOne').savePaymentMethod(authorizationStructure);
    },
    zeroDollarAuthSetupCall: function zeroDollarAuthSetupCall() {
      var _this2 = this;

      var setupParams = {
        appID: this.appID,
        userID: this.userID,
        currentLocale: this.$i18n.locale + '_US'
      };
      this.$store.dispatch('RPP/zeroDollarAuthorizationSetup', setupParams).then(function (resp) {
        _this2.ETransID = resp.ETransID;
        _this2.accountGroupCode = resp.clientSideVendorParameters.accountGroupCode;
        _this2.customerID = resp.clientSideVendorParameters.customerID;
        _this2.sessionID = resp.clientSideVendorParameters.sessionID;
        $('#portalOneContainer').portalOne();

        _this2.setupEventListeners();

        _this2.showPremodal = true;
      }).catch(function (err) {
        _this2.handleError(err, 'setup');
      }).finally(function () {
        _this2.loading = false;
      });
    },
    saveVendorUIResponseCall: function saveVendorUIResponseCall(eventType, responseData, responseContext) {
      var _this3 = this;

      var vendorResponseParams = {
        ETransID: this.ETransID,
        merchantID: this.accountGroupCode,
        // Substituting value for non-payment
        vendorResponseData: responseData,
        vendorResponseContext: responseContext,
        requestPayload: this.requestPayload
      };
      this.$store.dispatch('RPP/saveVendorUIResponse', vendorResponseParams).then(function (resp) {
        if (resp == true && eventType === 'onOneIncSaveCompleteEvent') {
          _this3.zeroDollarAuthWrapupCall();
        } else if (resp == true && eventType === 'onOneIncErrorEvent') {// OneInc error, vendor response save success
        } else if (resp == false && eventType === 'onOneIncSaveCompleteEvent') {
          // OneInc success, vendor response save fail
          throw {
            name: 'saveSuccessErr',
            errorArray: ['OneInc save payment success, internal save response failed.']
          };
        } else if (resp == false && eventType === 'onOneIncErrorEvent') {
          // OneInc error, vendor response save fail
          throw {
            name: 'saveErrorErr',
            errorArray: ['OneInc error occurred, internal save response failed.']
          };
        }
      }).catch(function (err) {
        var errorType = '';

        if (err.name === 'saveSuccessIssue') {
          errorType = 'saveSuccessFail';
        } else if (err.name === 'saveErrorIssue') {
          errorType = 'saveErrorFail';
        } else {
          errorType = 'saveUIResponse';
        }

        _this3.handleError(err, errorType);
      });
    },
    zeroDollarAuthWrapupCall: function zeroDollarAuthWrapupCall() {
      var _this4 = this;

      var wrapupParams = {
        ETransID: this.ETransID,
        userID: this.userID,
        cardHolderOnPolicyFlag: this.preForm.cardholderOnPolicyFlag,
        cardHolderOnPolicyReason: this.preForm.cardholderOnPolicyReason,
        cardHolderPhone: this.preForm.cardholderPhone,
        cardHolderEmail: this.preForm.cardholderEmail,
        cardHolderEmailValid: this.preForm.cardholderEmail === '' ? 0 : 1
      };
      this.$store.dispatch('RPP/zeroDollarAuthorizationWrapup', wrapupParams).then(function (resp) {
        _this4.showOneIncModal = false;

        _this4.$store.commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false');

        _this4.$store.commit('billingAndPayments/clear_billingAndPayments_module_state');

        _this4.$router.push({
          name: 'billingAndPayments',
          params: {
            RPPSuccessMessage: 'Shared.Element.InformationUpdated'
          }
        });
      }).catch(function (err) {
        _this4.handleError(err, 'wrapup');
      });
    },
    setupEventListeners: function setupEventListeners() {
      $('#portalOneContainer').on('portalOne.load', this.onOneIncLoadEvent).on('portalOne.unload', this.onOneIncUnloadEvent).on('portalOne.error', this.onOneIncErrorEvent).on('portalOne.saveComplete', this.onOneIncSaveCompleteEvent).on('portalOne.saveCanceled', this.onOneIncSaveCanceledEvent);
    },
    onOneIncLoadEvent: function onOneIncLoadEvent() {
      var _this5 = this;

      setTimeout(function () {
        _this5.showOneIncModal = true;
        _this5.loading = false;
      }, 2000);
    },
    onOneIncUnloadEvent: function onOneIncUnloadEvent() {},
    onOneIncErrorEvent: function onOneIncErrorEvent(e, data) {
      var responseData = JSON.stringify(data);
      var responseContext = data.Details === undefined ? 'authError' : 'interfaceError';
      this.saveVendorUIResponseCall('onOneIncErrorEvent', responseData, responseContext);

      if (responseContext === 'interfaceError') {
        this.error = this.getOneIncModalErrorMessage('modalLoad');
        window.scrollTo(0, 0);
        this.showOneIncModal = false;
        setTimeout(function () {
          vm.$router.push({
            name: 'billingAndPayments'
          });
        }, 2000);
      }
    },
    onOneIncSaveCompleteEvent: function onOneIncSaveCompleteEvent(e, data) {
      this.loading = true;
      var responseData = JSON.stringify(data);
      var responseContext = 'authComplete';
      this.saveVendorUIResponseCall('onOneIncSaveCompleteEvent', responseData, responseContext);
    },
    onOneIncSaveCanceledEvent: function onOneIncSaveCanceledEvent() {
      this.showOneIncModal = false;
      this.showPremodal = true;
    },
    getOneIncModalErrorMessage: function getOneIncModalErrorMessage(errType) {
      switch (errType) {
        case 'modalLoad':
          return 'Shared.Element.Errors.RPPModalLoad';

        case 'setup':
          return 'Shared.Element.Errors.RPPSetup';

        case 'saveUIResponse':
          return 'Shared.Element.Errors.RPPSaveUIResponse';

        case 'wrapup':
          return 'Shared.Element.Errors.RPPWrapup';

        case 'saveErrorFail':
          return 'Shared.Element.Errors.RPPSaveErrorFail';

        case 'saveSuccessFail':
          return 'Shared.Element.Errors.RPPSaveSuccessFail';

        default:
          return 'An error has occurred. Please cancel and try again. (999)';
      }
    },
    handleError: function handleError(err, errType) {
      var _this6 = this;

      this.showPremodal = false;
      this.showOneIncModal = false;
      this.error = this.getOneIncModalErrorMessage(errType);
      var errors = '';
      var status = err.response.status || 0;

      if (status === 400 || status === 404 || status === 500) {
        errors = err.errorArray.join();
      }

      if (status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this6.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else {
        this.logRPPError(status, errors, errType);
        setTimeout(function () {
          _this6.$router.push({
            name: 'billingAndPayments'
          });
        }, 5000);
      }
    },
    logRPPError: function logRPPError(errorStatus, errorString, errorType) {
      this.$store.dispatch('RPP/logRPPError', {
        policyNumber: this.policyNumber,
        errorStatus: errorStatus,
        errorInfo: errorString,
        errorType: errorType,
        appID: this.appID,
        ETransID: this.ETransID || 0,
        accountGroupCode: this.accountGroupCode || '',
        requestPayload: this.requestPayload
      });
    },
    includejQuery: function includejQuery() {
      if (!document.getElementById("jQueryScript")) {
        var script = document.createElement('script');
        script.src = '/js/jquery-3.5.1.min.js';
        script.type = 'text/javascript';
        script.id = 'jQueryScript';
        document.head.appendChild(script);
      }
    },
    includeOneIncJavascript: function includeOneIncJavascript() {
      if (!document.getElementById("oneIncScript")) {
        var script = document.createElement('script');
        script.src = this.oneIncETransVendorParam;
        script.type = 'text/javascript';
        script.id = 'oneIncScript';
        document.head.appendChild(script);
      }
    }
  }
};