import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'Footer',
  computed: _objectSpread(_objectSpread({}, mapGetters('siteConfig', ['mainSiteURL', 'termsURL', 'privacyURL', 'address', 'cityStateZip', 'mainPhone', 'mainPhoneDigits', 'copyright', 'companyName', 'logo', 'domainID'])), {}, {
    desktopLogoSrc: function desktopLogoSrc() {
      return this.logo ? require('@/assets/images/desktop/' + this.logo) : '';
    },
    mobileLogoSrc: function mobileLogoSrc() {
      return this.logo ? require('@/assets/images/mobile/' + this.logo) : '';
    },
    showOneTrustLink: function showOneTrustLink() {
      return !!this.domainID;
    }
  })
};