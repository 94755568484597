import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'documentsView',
  data: function data() {
    return {
      loading: false,
      error: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: '',
      sortBy: 'termEffectiveDate',
      sortDesc: true
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters('documents', ['defaultTerm', 'documentsInfoExist', 'documentItems', 'formsCategory', 'IDCardExists', 'initialCount', 'logClickEvent', 'receiptURL', 'terms'])), mapGetters('account', ['selectedPolicyNumber'])), {}, {
    fields: function fields() {
      return [{
        key: 'termEffectiveDate',
        label: this.$t('Shared.Element.EffectiveDate'),
        sortable: true
      }, {
        key: 'display',
        label: this.$t('Shared.Element.Display'),
        sortable: true
      }];
    },
    termEffective: function termEffective() {
      var selectedTerm = this.filter.split(' ');
      var effectiveDate;
      this.terms.forEach(function (term) {
        if (selectedTerm[1] === term.name) {
          effectiveDate = term.effectiveDate;
        }
      });
      return effectiveDate;
    },
    termExpiration: function termExpiration() {
      var selectedTerm = this.filter.split(' ');
      var expirationDate;
      this.terms.forEach(function (term) {
        if (selectedTerm[1] === term.name) {
          expirationDate = term.expirationDate;
        }
      });
      return expirationDate;
    }
  }),
  created: function created() {
    this.getPageData();
    this.$store.dispatch('account/setSelectedTabName', 'Documents.Header');
  },
  methods: {
    sortDates: function sortDates(a, b, key) {
      if (key === 'termEffectiveDate') {
        return new Date(a[key]) - new Date(b[key]);
      } else {
        return false;
      }
    },
    onFiltered: function onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateFilterCategory: function updateFilterCategory(catName) {
      var selectedTerm = this.filter.split(' ');
      this.filter = catName + ' ' + selectedTerm[1];
    },
    updateFilterTerm: function updateFilterTerm(termName) {
      this.filter = this.filter.length ? this.filter.split(' ')[0] + ' ' + termName : 'Policy ' + termName;
    },
    getPageData: function getPageData() {
      var _this = this;

      this.loading = true;

      if (!this.documentsInfoExist) {
        if (this.$store.getters['account/doesSelectedPolicyNumberExist']) {
          this.callAPI();
        } else {
          this.$store.dispatch('account/refreshSessionInfo').then(function (response) {
            _this.callAPI();
          }).catch(function (err) {
            return _this.handleError(err);
          });
        }
      } else {
        this.updateFilterTerm(this.defaultTerm);
        this.totalRows = this.initialCount;
        this.loading = false;
      }
    },
    callAPI: function callAPI() {
      var _this2 = this;

      var documentPromise = this.$store.dispatch('account/requestData', this.$store.getters['documents/documentsAPIParams']);
      var producerPromise = this.$store.dispatch('account/requestData', this.$store.getters['producer/producerAPIParams']);
      Promise.all([documentPromise, producerPromise]).then(function () {
        _this2.loading = false;

        _this2.updateFilterTerm(_this2.defaultTerm);

        _this2.totalRows = _this2.initialCount;
      }).catch(function (err) {
        return _this2.handleError(err);
      });
    },
    sendGAEvent: function sendGAEvent(docName) {
      this.$ga.event('OCP', 'download', docName);
    },
    logDocClickEvent: function logDocClickEvent(name) {
      if (this.logClickEvent) {
        this.$store.dispatch('documents/logDocumentClickEvent', {
          fileName: name,
          policyNumber: this.$store.getters['account/selectedPolicyNumber']
        });
      }
    },
    handleError: function handleError(err) {
      var _this3 = this;

      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this3.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 404 || err.response.status === 500) {
        this.status = err.response.status;
        this.error = err.errorArray[0];
        this.loading = false;
      }
    }
  },
  watch: {
    documentItems: function documentItems() {
      this.updateFilterTerm(this.defaultTerm);
    },
    selectedPolicyNumber: function selectedPolicyNumber() {
      this.error = null;
    }
  }
};