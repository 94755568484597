import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StoreRef from './TheStoreRef.vue';
export default {
  name: 'PageHeader',
  components: {
    StoreRef: StoreRef
  },
  data: function data() {
    return {
      loading: false,
      showMenu: false
    };
  },
  computed: {
    storedAPIParamsString: function storedAPIParamsString() {
      return this.$route.name + '/' + this.$route.name + 'APIParams'; // Ex: 'policy/policyAPIParams'
    },
    deleteOtherVuexStatesString: function deleteOtherVuexStatesString() {
      return this.$route.name + '/' + 'deleteNon' + this.$route.name[0].toUpperCase() + this.$route.name.slice(1) + 'Info';
    },
    selectedTabName: function selectedTabName() {
      return this.$store.getters['account/selectedTabName'];
    },
    unselectedPolicies: function unselectedPolicies() {
      var _this = this;

      var policyArray = this.$store.getters['account/policyArray'];
      return policyArray.filter(function (policy) {
        return policy !== _this.$store.getters['account/selectedPolicyNumber'];
      });
    },
    selectedPolicyNumber: function selectedPolicyNumber() {
      return this.$store.getters['account/selectedPolicyNumber'];
    }
  },
  // Document listeners to close the policy dropdown when a user click off the <div>
  created: function created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed: function destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    updateNewSelectedPolicyNumber: function updateNewSelectedPolicyNumber(newSelectedPolicy) {
      var _this2 = this;

      this.loading = true;
      var fallbackCurrentPolicy = this.$store.getters['account/selectedPolicyNumber'];
      this.$store.dispatch('account/setSelectedPolicyNumber', newSelectedPolicy).then(function () {
        var deleteOtherVuexStatesPromise = _this2.$store.dispatch(_this2.deleteOtherVuexStatesString);

        var pageDataPromise = _this2.$store.dispatch('account/requestData', _this2.$store.getters[_this2.storedAPIParamsString]);

        var producerPromise = _this2.$store.dispatch('account/requestData', _this2.$store.getters['producer/producerAPIParams']);

        Promise.all([deleteOtherVuexStatesPromise, pageDataPromise, producerPromise]).then(function () {
          _this2.showMenu = false;
          _this2.loading = false;
        }).catch(function () {
          _this2.handleError(fallbackCurrentPolicy);
        });
      }).catch(function () {
        _this2.handleError(fallbackCurrentPolicy);
      });
    },
    handleError: function handleError(originalPolicy) {
      this.$store.dispatch('account/setSelectedPolicyNumber', originalPolicy);
      this.$forceUpdate();
    },
    documentClick: function documentClick(e) {
      var el = this.$refs.dropdownMenu;

      if (el !== undefined) {
        var target = e.target;

        if (el !== target && !el.contains(target)) {
          this.showMenu = false;
        }
      }
    }
  }
};