import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { strongPassword, verificationCodeRegex } from '@/common/validations';
import AccountBase from './AccountBase.vue';
import { required, sameAs, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
export default {
  name: 'settingsView',
  extends: AccountBase,
  data: function data() {
    return {
      errors: [],
      loading: false,
      modalLoading: false,
      alertError: false,
      alertSuccess: false,
      alertSuccessMessage: '',
      formPasswordState: true,
      formPasswordConfirmationState: true,
      form: {
        password: '',
        passwordConfirmation: ''
      },
      newEmail: '',
      userAuthCode: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapGetters('account', ['selectedPolicyNumber'])), mapGetters('policy', ['policyInfo', 'policyInfoExists', 'EDeliveryEnrolled'])), mapGetters('profile', ['messagingAuthorizationKey', 'showEnterEmailAddress', 'showGetNewCode', 'showWrongCode', 'showCodeIsSent', 'showVerifyInput', 'showResendCode', 'thanksMessage'])), {}, {
    locale: function locale() {
      return this.$i18n.locale + '_US';
    },
    firstName: function firstName() {
      return this.$store.getters['account/firstName'];
    },
    lastName: function lastName() {
      return this.$store.getters['account/lastName'];
    },
    email: function email() {
      return this.$store.getters['account/email'];
    },
    selectedPolicyNumber: function selectedPolicyNumber() {
      return this.$store.getters['account/selectedPolicyNumber'];
    }
  }),
  validations: {
    form: {
      password: {
        required: required,
        strongPassword: strongPassword
      },
      passwordConfirmation: {
        required: required,
        sameAsPassword: sameAs('password')
      }
    },
    newEmail: {
      required: required,
      email: email
    },
    userAuthCode: {
      required: required,
      verificationCodeRegex: verificationCodeRegex
    }
  },
  created: function created() {
    this.makeRefreshRequest();
    this.getPageData();
    this.$store.dispatch('account/setSelectedTabName', 'Profile.Header');
  },
  methods: {
    makeRefreshRequest: function makeRefreshRequest() {
      if (!this.$store.getters['account/selectedPolicyNumber']) {
        this.$store.dispatch('account/refreshSessionInfo');
      }
    },
    updatePassword: function updatePassword() {
      var _this = this;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.validateForm();
      } else {
        this.loading = true;
        var password = this.form.password;
        var passwordConfirmation = this.form.passwordConfirmation;
        var currentLocale = this.locale;
        this.$store.dispatch('profile/updatePassword', {
          password: password,
          passwordConfirmation: passwordConfirmation,
          currentLocale: currentLocale
        }).then(function (resp) {
          _this.loading = false;
          _this.alertSuccessMessage = 'Reset.PasswordResetComplete';
          _this.alertSuccess = true;
        }).catch(function (err) {
          if (err.response.status === 403) {
            _this.$store.dispatch('authentication/logout').then(function () {
              return _this.$router.push({
                name: 'login',
                params: {
                  externalError: err.errorArray[0]
                }
              });
            });
          } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this.errors = err.errorArray;
            _this.alertError = true;
            _this.loading = false;
          }
        });
      }
    },
    modalclose: function modalclose() {
      this.$store.dispatch('profile/clearState');
    },
    modalOpen: function modalOpen() {
      this.$store.dispatch('profile/clearState');
      this.$bvModal.show('modal-EditEmail');
      this.errors = [];
    },
    sendResetEmail: function sendResetEmail(value) {
      var _this2 = this;

      this.errors = [];
      this.$v.$reset();
      this.$v.newEmail.$touch();

      if (this.$v.newEmail.$anyError) {
        this.validateEmailForm();
      } else {
        this.modalLoading = true;
        var newEmail = this.newEmail;
        var _email = this.email;
        var currentLocale = this.locale;
        var send = value === 'send';
        this.$store.dispatch('profile/sendResetEmail', {
          newEmail: newEmail,
          email: _email,
          currentLocale: currentLocale,
          send: send
        }).then(function (resp) {
          _this2.loading = false;
          _this2.modalLoading = false;
          _this2.userAuthCode = '';
        }).catch(function (err) {
          if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this2.errors = err.errorArray;
            _this2.alertError = true;
            _this2.loading = false;
            _this2.modalLoading = false;
          }
        });
      }
    },
    confirmEmailReset: function confirmEmailReset() {
      var _this3 = this;

      this.errors = [];
      this.$v.$reset();
      this.$v.userAuthCode.$touch();

      if (this.$v.userAuthCode.$anyError) {
        this.validateVerificationCodeForm();
      } else {
        this.modalLoading = true;
        var newEmail = this.newEmail;
        var _email2 = this.email;
        var messagingAuthorizationKey = this.messagingAuthorizationKey;
        var userAuthCode = this.userAuthCode;
        var currentLocale = this.locale;
        this.$store.dispatch('profile/confirmEmailReset', {
          newEmail: newEmail,
          email: _email2,
          messagingAuthorizationKey: messagingAuthorizationKey,
          userAuthCode: userAuthCode,
          currentLocale: currentLocale
        }).then(function (resp) {
          _this3.modalLoading = false;
          _this3.loading = false;

          if (resp === true) {
            _this3.$store.dispatch('account/setUserInfo', {
              firstName: _this3.firstName,
              lastName: _this3.lastName,
              email: _this3.newEmail
            });
          }
        }).catch(function (err) {
          if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this3.errors = err.errorArray;
            _this3.alertError = true;
            _this3.loading = false;
            _this3.modalLoading = false;
          }
        });
      }
    },
    validateForm: function validateForm() {
      this.errors = [];
      if (!this.$v.form.password.required) this.errors.push('Profile.EnterNewPassword');
      if (!this.$v.form.passwordConfirmation.required) this.errors.push('Shared.Element.Errors.PasswordConfirmation');
      this.alertError = true;
    },
    validateEmailForm: function validateEmailForm() {
      this.errors = [];
      if (!this.$v.newEmail.required) this.errors.push('Shared.Element.Errors.Email');
      if (!this.$v.newEmail.email) this.errors.push('Profile.EnterNewEmail');
      this.alertError = true;
    },
    validateVerificationCodeForm: function validateVerificationCodeForm() {
      this.errors = [];
      if (!this.$v.userAuthCode.required || !this.$v.userAuthCode.verificationCodeRegex) this.errors.push('Shared.Element.Errors.VerificationCode');
      this.alertError = true;
    },
    validateState: function validateState(item) {
      var $dirty = item.$dirty,
          $error = item.$error;
      return $dirty ? !$error : null;
    },
    dismissAlert: function dismissAlert() {
      this.$v.form.$reset();
      this.alertError = false;
    },
    getPageData: function getPageData() {
      var _this4 = this;

      this.loading = true;

      if (this.$store.getters['account/doesSelectedPolicyNumberExist']) {
        this.callAPI();
      } else {
        // There was a page refresh. Need to populate siteConfig, selectedPolicyNumber, and policyArray in store.
        this.$store.dispatch('account/refreshSessionInfo').then(function (response) {
          _this4.callAPI();
        }).catch(function (err) {
          return _this4.handleError(err);
        });
      }
    },
    callAPI: function callAPI() {
      var _this5 = this;

      var policyPromise = this.$store.dispatch('account/requestData', this.$store.getters['policy/policyAPIParams']);
      Promise.all([policyPromise]).then(function () {
        _this5.loading = false;
      }).catch(function (err) {
        return _this5.handleError(err);
      });
    },
    handleError: function handleError(err) {
      var _this6 = this;

      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this6.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 404 || err.response.status === 500) {
        this.status = err.response.status;
        this.error = err.errorArray;
        this.loading = false;
      }
    }
  }
};