import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { CVVRegex, CCExpDateRegex, zipRegex, phoneRegex } from '@/common/validations';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
export default {
  name: 'CardConnectBillingView',
  data: function data() {
    return {
      // Template flow control
      error: null,
      loading: false,
      showCCNumberError: false,
      // T&C data
      termsAndConditions: {
        status: 'notAccepted',
        show: false,
        displayError: false
      },
      // RPP form data
      form: {
        ExpireDate: '',
        CVVCode: '',
        cardholderOnPolicyFlag: undefined,
        cardholderOnPolicyReason: '',
        cardholderName: '',
        cardholderAddress: '',
        cardholderAddress2: '',
        cardholderCity: '',
        cardholderState: '',
        cardholderZip: '',
        cardholderPhone: '',
        cardholderEmail: '',
        CCToken: '',
        cardholderCountry: 'US',
        cardholderEmailValid: '0',
        lastFourDigits: '0',
        cardExpireMonth: '',
        cardExpireYear: '',
        isCardConnect: '1',
        cardType: '',
        policyNumber: '',
        appID: '',
        userID: 0
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('RPP', ['prefillCardholderAddress', 'prefillCardholderAddress2', 'prefillCardholderPhone', 'prefillCardholderEmail', 'prefillCardholderCity', 'prefillCardholderState', 'prefillCardholderZip', 'eTransVendor', 'cardConnectETransVendorParamDesktop', 'cardConnectETransVendorParamMobile', 'appID', 'RPPPullDate', 'RPPPrefillData', 'RP_StatusCode', 'RP_hasActiveNonRenewal', 'stateABBRS'])), {}, {
    signedUp: function signedUp() {
      // Need to know if user if form is for Edit or SignUp
      return this.$store.getters['billingAndPayments/RPPShowEdit'];
    }
  }),
  validations: {
    form: {
      ExpireDate: {
        required: required,
        CCExpDateRegex: CCExpDateRegex
      },
      CVVCode: {
        required: required,
        CVVRegex: CVVRegex
      },
      cardholderOnPolicyFlag: {
        required: required
      },
      cardholderName: {
        required: required
      },
      cardholderAddress: {
        required: required
      },
      cardholderCity: {
        required: required
      },
      cardholderState: {
        required: required
      },
      cardholderZip: {
        required: required,
        zipRegex: zipRegex
      },
      cardholderPhone: {
        phoneRegex: phoneRegex
      },
      cardholderEmail: {
        email: email
      },
      cardholderOnPolicyReason: {
        required: requiredIf(function (form) {
          // Vuelidate doesn't allow arrow function here. Shame.
          return this.form.cardholderOnPolicyFlag === '0';
        })
      }
    }
  },
  created: function created() {
    if (!this.eTransVendor) this.$router.push({
      name: 'billingAndPayments'
    }); // If refresh, send user back to billing page.

    window.addEventListener('message', this.receiveCCToken, false);
    this.$store.dispatch('account/setSelectedTabName', 'Shared.Element.RPPSignup');
  },
  destroyed: function destroyed() {
    window.removeEventListener('message', this.receiveCCToken);
  },
  mounted: function mounted() {
    this.prefillForm();
  },
  methods: {
    saveRPPChange: function saveRPPChange() {
      var _this = this;

      this.error = null;
      this.$v.$reset();
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.error = 'Shared.Element.Errors.CorrectErrors';
        window.scrollTo(0, 0);
      } else {
        this.loading = true;

        if (!this.signedUp) {
          if (this.termsAndConditions.status !== 'accepted') {
            this.termsAndConditions.displayError = true;
            this.termsAndConditions.show = false;
            this.error = 'Shared.Element.Errors.TermsAndConditions';
            this.loading = false;
            window.scrollTo(0, 0);
          } else {
            this.termsAndConditions.displayError = false;
          }
        }

        if (!this.error) {
          if (this.form.CCToken && this.form.CCToken.length === 16 && this.form.CCToken.substring(0, 1) === '9') {
            this.parseExpireDate();
            if (this.form.cardholderEmail) this.form.cardholderEmailValid = '1';
            this.$store.dispatch('RPP/zeroDollarRPPAuthorization', this.form).then(function (resp) {
              if (resp) {
                _this.$store.commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false');

                _this.$store.commit('billingAndPayments/clear_billingAndPayments_module_state');

                _this.$router.push({
                  name: 'billingAndPayments',
                  params: {
                    RPPSuccessMessage: 'Shared.Element.InformationUpdated'
                  }
                });
              }
            }).catch(function (err) {
              _this.handleError(err);
            }).finally(function () {
              _this.loading = false;
            });
          } else {
            this.error = 'Shared.Element.Errors.CCToken';
            this.showCCNumberError = true;
            this.loading = false;
            window.scrollTo(0, 0);
          }
        } else {
          this.loading = false;
        }
      }
    },
    receiveCCToken: function receiveCCToken(e) {
      var token = JSON.parse(e.data);
      this.form.CCToken = token.message;
      this.showCCNumberError = false;

      if (token.validationError) {
        this.error = 'Shared.Element.Errors.InputCCError';
      }
    },
    parseExpireDate: function parseExpireDate() {
      this.form.cardExpireMonth = '';
      this.form.cardExpireYear = '';
      var today = new Date();
      var currYear = today.getFullYear();
      var formMonth = parseInt(this.form.ExpireDate.slice(0, 2));
      var formYear = this.form.ExpireDate.slice(2);
      var yearPrefix = currYear.toString().slice(0, 2);
      var formFullYear = parseInt(yearPrefix + formYear);
      this.form.cardExpireMonth = formMonth;
      this.form.cardExpireYear = formFullYear;
    },
    prefillForm: function prefillForm() {
      this.form.cardholderAddress = this.prefillCardholderAddress || '';
      this.form.cardholderAddress2 = this.prefillCardholderAddress2 || '';
      this.form.cardholderPhone = this.prefillCardholderPhone || '';
      this.form.cardholderEmail = this.prefillCardholderEmail || '';
      this.form.cardholderCity = this.prefillCardholderCity || '';
      this.form.cardholderState = this.prefillCardholderState || '';
      this.form.cardholderZip = this.prefillCardholderZip || '';
      this.form.policyNumber = this.$store.getters['account/selectedPolicyNumber'];
      this.form.appID = this.appID || '';
      this.form.userID = this.$store.getters['siteConfig/RPPUserID'];
    },
    handleError: function handleError(err) {
      var _this2 = this;

      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this2.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
        this.errors = err.errorArray;
        this.alertError = true;
      }
    }
  }
};