import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StoreRef from '@/components/shared/TheStoreRef.vue';
import Driver from '@/components/account/policy/Driver.vue';
import Vehicle from '@/components/account/policy/Vehicle.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'policyView',
  components: {
    storeRef: StoreRef,
    driver: Driver,
    vehicle: Vehicle
  },
  data: function data() {
    return {
      loading: false,
      error: null,
      status: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters('policy', ['policyInfo', 'policyInfoExists', 'mailingAddressLine1', 'mailingAddressLine2', 'garageAddressLine1', 'garageAddressLine2', 'IDCardExists', 'IDCardLink'])), mapGetters('account', ['selectedPolicyNumber'])), {}, {
    statusBadgeVariant: function statusBadgeVariant() {
      var successStatuses = ['In-Force', 'Reinstated Without Lapse', 'Reinstated With Lapse', 'Renewed'];
      var policyStatus = this.$store.getters['policy/policyStatus'];
      if (policyStatus) return successStatuses.indexOf(policyStatus) !== -1 ? 'success' : 'danger';
      return '';
    },
    insuredDrivers: function insuredDrivers() {
      return this.policyInfo.drivers.filter(function (driver) {
        return driver.category === 'P';
      });
    },
    excludedDrivers: function excludedDrivers() {
      return this.policyInfo.drivers.filter(function (driver) {
        return driver.category === 'E';
      });
    }
  }),
  created: function created() {
    this.getPageData();
    this.$store.dispatch('account/setSelectedTabName', 'Policy.Header');
  },
  methods: {
    getPageData: function getPageData() {
      var _this = this;

      this.loading = true;

      if (!this.policyInfoExists) {
        // If already visited this tab and store data exists.
        if (this.$store.getters['account/doesSelectedPolicyNumberExist']) {
          this.callAPI();
        } else {
          // There was a page refresh. Need to populate siteConfig, selectedPolicyNumber, and policyArray in store.
          this.$store.dispatch('account/refreshSessionInfo').then(function (response) {
            _this.callAPI();
          }).catch(function (err) {
            return _this.handleError(err);
          });
        }
      } else {
        this.loading = false;
      }
    },
    callAPI: function callAPI() {
      var _this2 = this;

      var policyPromise = this.$store.dispatch('account/requestData', this.$store.getters['policy/policyAPIParams']);
      var producerPromise = this.$store.dispatch('account/requestData', this.$store.getters['producer/producerAPIParams']);
      Promise.all([policyPromise, producerPromise]).then(function () {
        _this2.loading = false;
      }).catch(function (err) {
        return _this2.handleError(err);
      });
    },
    handleError: function handleError(err) {
      var _this3 = this;

      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this3.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 404 || err.response.status === 500) {
        this.status = err.response.status;
        this.error = err.errorArray[0];
        this.loading = false;
      }
    }
  },
  watch: {
    selectedPolicyNumber: function selectedPolicyNumber() {
      this.error = null;
    }
  }
};