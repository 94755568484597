var state = {
  doesBillingAndPaymentsInfoExist: false,
  billingInfo: {},
  billingAndPaymentsAPIParams: {
    urlString: '/api/account/billing',
    jsonDataString: 'billingAndRPPInfo',
    viewType: 'billingAndPayments'
  }
};
var getters = {
  billingInfo: function billingInfo(state) {
    return state.billingInfo;
  },
  billingAndPaymentsInfoExist: function billingAndPaymentsInfoExist(state) {
    return state.doesBillingAndPaymentsInfoExist;
  },
  billingAndPaymentsAPIParams: function billingAndPaymentsAPIParams(state) {
    return state.billingAndPaymentsAPIParams;
  },
  driverDOB: function driverDOB(state) {
    return state.billingInfo.driver ? state.billingInfo.driver.DOB : '';
  },
  RPPShowSignUp: function RPPShowSignUp(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.showSignUp : '';
  },
  RPPShowEdit: function RPPShowEdit(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.showEdit : '';
  },
  RPPDisableButton: function RPPDisableButton(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.disableButton : '';
  },
  RPPStatus: function RPPStatus(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.status : '';
  },
  RPPButtonText: function RPPButtonText(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.buttonText : '';
  },
  RPPFullCardType: function RPPFullCardType(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.fullCardType : '';
  },
  RPPCCNumber4: function RPPCCNumber4(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.CCNumber4 : '';
  },
  RPPExpDate: function RPPExpDate(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.expDate : '';
  },
  RPPAmountDue: function RPPAmountDue(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.amountDue : '';
  },
  RPPPullDate: function RPPPullDate(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.pullDate : '';
  },
  RPPToolTipMessage: function RPPToolTipMessage(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.message : '';
  },
  RPPGoogleAnalyticsLabel: function RPPGoogleAnalyticsLabel(state) {
    return state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.googleEventLabel : '';
  }
};
var mutations = {
  set_billingAndPayments_data: function set_billingAndPayments_data(state, billingData) {
    state.billingInfo = billingData;
  },
  set_doesBillingAndPaymentsInfoExist_to_true: function set_doesBillingAndPaymentsInfoExist_to_true(state) {
    state.doesBillingAndPaymentsInfoExist = true;
  },
  set_doesBillingAndPaymentsInfoExist_to_false: function set_doesBillingAndPaymentsInfoExist_to_false(state) {
    state.doesBillingAndPaymentsInfoExist = false;
  },
  clear_billingAndPayments_module_state: function clear_billingAndPayments_module_state(state) {
    state.billingInfo = {};
  }
};
var actions = {
  deleteNonBillingAndPaymentsInfo: function deleteNonBillingAndPaymentsInfo(_ref) {
    var commit = _ref.commit;
    commit('communication/clear_communication_module_state', null, {
      root: true
    });
    commit('policy/clear_policy_module_state', null, {
      root: true
    });
    commit('policy/set_doesPolicyInfoExist_to_false', null, {
      root: true
    });
    commit('profile/clear_profile_module_state', null, {
      root: true
    });
    commit('documents/clear_documents_module_state', null, {
      root: true
    });
    commit('documents/set_doesDocumentsInfoExist_to_false', null, {
      root: true
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};