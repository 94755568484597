import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'StoreReference',
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters('producer', ['producerInfo', 'producerInfoExists', 'producerWorkPhoneDigits']))
};