import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AutomaticPayments from '@/components/account/RPP/AutomaticPayments.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'billingAndPaymentsView',
  components: {
    AutomaticPayments: AutomaticPayments
  },
  props: ['RPPSuccessMessage'],
  data: function data() {
    return {
      loading: false,
      status: null,
      error: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('billingAndPayments', ['billingInfo', 'billingAndPaymentsInfoExist', 'driverDOB', 'RPPShowSignUp', 'RPPShowEdit'])), {}, {
    paymentURL: function paymentURL() {
      var currentLanguage = this.$i18n.locale;
      var thirdParty = this.$store.getters['siteConfig/thirdParty'];
      return currentLanguage === 'es' && thirdParty ? this.$store.getters['siteConfig/paymentURL'].concat('?lang=2') : this.$store.getters['siteConfig/paymentURL'];
    },
    dynamicTarget: function dynamicTarget() {
      var thirdParty = this.$store.getters['siteConfig/thirdParty'];
      return thirdParty ? '_self' : '_blank';
    },
    driverPolicyNumber: function driverPolicyNumber() {
      return this.$store.getters['account/selectedPolicyNumber'];
    },
    showAutomaticPaymentsSection: function showAutomaticPaymentsSection() {
      return this.RPPShowSignUp || this.RPPShowEdit;
    }
  }),
  created: function created() {
    this.getPageData();
    this.$store.dispatch('account/setSelectedTabName', 'Billing.Header');
  },
  methods: {
    getPageData: function getPageData() {
      var _this = this;

      this.loading = true;

      if (!this.billingAndPaymentsInfoExist) {
        if (this.$store.getters['account/doesSelectedPolicyNumberExist']) {
          this.callAPI();
        } else {
          this.$store.dispatch('account/refreshSessionInfo').then(function (response) {
            _this.callAPI();
          }).catch(function (err) {
            return _this.handleError(err);
          });
        }
      } else {
        this.loading = false;
      }
    },
    callAPI: function callAPI() {
      var _this2 = this;

      var billingPromise = this.$store.dispatch('account/requestData', this.$store.getters['billingAndPayments/billingAndPaymentsAPIParams']);
      var producerPromise = this.$store.dispatch('account/requestData', this.$store.getters['producer/producerAPIParams']);
      Promise.all([billingPromise, producerPromise]).then(function () {
        _this2.loading = false;
      }).catch(function (err) {
        _this2.handleError(err);
      });
    },
    // One time payment hidden form submission
    processForm: function processForm() {
      if (this.driverPolicyNumber.length === 13 && this.driverDOB.length === 10) {
        this.$ga.event('OCP', 'click', 'Payment - Logged In');
        document.getElementById('oneTimePaymentForm').submit();
      } else {
        alert('Error!');
      }
    },
    // Emission from <Automatic-Payments/> child component
    loadRPPForm: function loadRPPForm(value) {
      if (!value.error) {
        this.loading = value.loading;
      } else {
        this.handleError(value.error);
      }
    },
    handleError: function handleError(err) {
      var _this3 = this;

      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this3.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
        this.status = err.response.status;
        this.error = err.errorArray[0];
        this.loading = false;
      }
    }
  }
};