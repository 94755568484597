import "core-js/modules/es.array.splice.js";
var state = {
  doesPolicyInfoExist: false,
  EDeliveryCommunications: [],
  EDeliveryEnrolled: 'Never enrolled',
  EDeliveryEnrollable: true,
  EDeliverySMSPhoneNumber: '',
  mailingAddressLine1: '',
  mailingAddressLine2: '',
  policyInfo: {},
  policyAPIParams: {
    urlString: '/api/account/policy',
    jsonDataString: 'policyInfo',
    viewType: 'policy'
  }
};
var getters = {
  availablePhoneNumbers: function availablePhoneNumbers(state) {
    var allNumbers = [];
    /* phone: home, phone2: mobile, phone3: alt */

    if (state.policyInfo.phone) allNumbers.push(state.policyInfo.phone);
    if (state.policyInfo.phone2) allNumbers.push(state.policyInfo.phone2);
    if (state.policyInfo.phone3) allNumbers.push(state.policyInfo.phone3);
    if (allNumbers.indexOf(state.EDeliverySMSPhoneNumber) >= 0) allNumbers.splice(allNumbers.indexOf(state.EDeliverySMSPhoneNumber), 1);
    return allNumbers;
  },
  EDeliveryEnrolled: function EDeliveryEnrolled(state) {
    return state.EDeliveryEnrolled;
  },
  EDeliveryEnrollable: function EDeliveryEnrollable(state) {
    return state.EDeliveryEnrollable;
  },
  EDeliverySMSPhoneNumber: function EDeliverySMSPhoneNumber(state) {
    return state.EDeliverySMSPhoneNumber;
  },
  policyInfo: function policyInfo(state) {
    return state.policyInfo;
  },
  policyInfoExists: function policyInfoExists(state) {
    return state.doesPolicyInfoExist;
  },
  policyAPIParams: function policyAPIParams(state) {
    return state.policyAPIParams;
  },
  policyStatus: function policyStatus(state) {
    return state.policyInfo.policyStatus;
  },
  mailingAddressLine1: function mailingAddressLine1(state) {
    return state.mailingAddressLine1;
  },
  mailingAddressLine2: function mailingAddressLine2(state) {
    return state.mailingAddressLine2;
  },
  garageAddressLine1: function garageAddressLine1(state) {
    return state.policyInfo.address.garage.address1;
  },
  garageAddressLine2: function garageAddressLine2(state) {
    return state.policyInfo.address.garage.city + ', ' + state.policyInfo.address.garage.state + ' ' + state.policyInfo.address.garage.zip;
  },
  IDCardExists: function IDCardExists(state) {
    return !!state.policyInfo.IDCard.display;
  },
  IDCardLink: function IDCardLink(state) {
    return state.policyInfo.IDCard.display ? state.policyInfo.IDCard.locationEncrypted : '#';
  }
};
var mutations = {
  set_policy_data: function set_policy_data(state, policyData) {
    state.policyInfo = policyData;
    state.EDeliveryEnrolled = policyData.EDeliveryInfo.enrollmentStatus;
    state.EDeliveryEnrollable = policyData.EDeliveryInfo.enrollable;
    state.EDeliverySMSPhoneNumber = policyData.EDeliveryInfo.communications.length ? policyData.EDeliveryInfo.communications[0].phoneNumber : '';
    state.mailingAddressLine1 = policyData.address.mailing.address1;
    state.mailingAddressLine2 = policyData.address.mailing.city + ', ' + policyData.address.mailing.state + ' ' + policyData.address.mailing.zip;
    state.EDeliveryCommunications = policyData.EDeliveryInfo.communications;
  },
  set_EDelivery_enrolled: function set_EDelivery_enrolled(state, value) {
    state.EDeliveryEnrolled = value;
    state.EDeliveryEnrollable = value !== 'Enrolled';
  },
  set_EDeliverySMSPhoneNumber: function set_EDeliverySMSPhoneNumber(state, value) {
    state.EDeliverySMSPhoneNumber = value;
  },
  set_doesPolicyInfoExist_to_true: function set_doesPolicyInfoExist_to_true(state) {
    state.doesPolicyInfoExist = true;
  },
  set_doesPolicyInfoExist_to_false: function set_doesPolicyInfoExist_to_false(state) {
    state.doesPolicyInfoExist = false;
  },
  clear_policy_module_state: function clear_policy_module_state(state) {
    state.policyInfo = {};
  }
};
var actions = {
  setEDeliveryEnrolled: function setEDeliveryEnrolled(_ref, value) {
    var commit = _ref.commit;
    commit('set_EDelivery_enrolled', value);
  },
  setEDeliverySMSPhoneNumber: function setEDeliverySMSPhoneNumber(_ref2, value) {
    var commit = _ref2.commit;
    commit('set_EDeliverySMSPhoneNumber', value);
  },
  setPolicyDataInStore: function setPolicyDataInStore(_ref3, policyData) {
    var commit = _ref3.commit;
    commit('set_policy_data', policyData);
  },
  deleteNonPolicyInfo: function deleteNonPolicyInfo(_ref4) {
    var commit = _ref4.commit;
    commit('billingAndPayments/clear_billingAndPayments_module_state', null, {
      root: true
    });
    commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false', null, {
      root: true
    });
    commit('communication/clear_communication_module_state', null, {
      root: true
    });
    commit('documents/clear_documents_module_state', null, {
      root: true
    });
    commit('documents/set_doesDocumentsInfoExist_to_false', null, {
      root: true
    });
    commit('profile/clear_profile_module_state', null, {
      root: true
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};