import _objectSpread from "E:/InetPub/wwwroot/OnlineCustomerPortal-VueJS/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'AutomaticPayments',
  props: ['RPPShowSignUp', 'RPPShowEdit'],
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('billingAndPayments', ['RPPDisableButton', 'RPPStatus', 'RPPButtonText', 'RPPPullDate', 'RPPAmountDue', 'RPPCCNumber4', 'RPPExpDate', 'RPPFullCardType', 'RPPToolTipMessage', 'RPPGoogleAnalyticsLabel'])), {}, {
    driverPolicyNumber: function driverPolicyNumber() {
      return this.$store.getters['account/selectedPolicyNumber'];
    }
  }),
  methods: {
    goToRPPForm: function goToRPPForm() {
      var _this = this;

      this.$emit('loading', {
        loading: true,
        error: null
      });
      this.$ga.event('OCP', 'click', this.RPPGoogleAnalyticsLabel);
      this.$store.dispatch('RPP/getRPPData', this.driverPolicyNumber).then(function (resp) {
        _this.$emit('loading', {
          loading: false,
          error: null
        });

        _this.redirectToRPP(resp);
      }).catch(function (err) {
        _this.$emit('loading', {
          loading: false,
          error: err
        });
      });
    },
    redirectToRPP: function redirectToRPP(vendor) {
      var RPPForm = '';

      if (vendor === 'oneInc') {
        RPPForm = 'RPPOneInc';
      } else if (vendor === 'cardConnect') {
        RPPForm = 'RPPCardConnect';
      } else if (vendor === 'stripe') {
        RPPForm = 'RPPStripe';
      } else {
        this.$router.push({
          name: 'login',
          params: {
            externalError: 'Shared.Element.Errors.500'
          }
        });
      }

      this.$router.push({
        name: RPPForm
      });
    }
  }
};